<template>
    <b-card class="m-0">
      <div v-if="!show.overlay">
        <table-render  id="table-tickets-manifest" :schema="schema" :actions="actions" :rows="rows" :fixed="true">
          <template #courierSlot="scope">
            <div>
              <img :src="`https://storage.googleapis.com/enviame-misc/carriers/logos/color/${scope.rowdata.carrier_code.toLowerCase()}.png`" alt="Courier logo" height="61">
            </div>
          </template>
          <template #actionsSlot="scope">
            <div class="table-manifest--buttons">
              <b-button variant="flat-success" v-b-tooltip.hover title="Manifiestos y etiquetas" class="p-0" @click="openModal(scope.rowdata)">
                <feather-icon icon="FileTextIcon" />
              </b-button>
              <b-button v-if="dataUser.role === 'admin' && isSourceDelivery" variant="flat-success" v-b-tooltip.hover title="Ver logs" class="p-0" @click="openLogs(scope.rowdata)">
                <feather-icon icon="ArchiveIcon"></feather-icon>
              </b-button>
              <b-button v-if="dataUser.role === 'admin' && isSourceDelivery" variant="flat-success" v-b-tooltip.hover title="Descargar Deliveries" class="p-0" @click="exportDeliveries(scope.rowdata)">
                <feather-icon icon="DownloadIcon"></feather-icon>
              </b-button>
              <b-button v-if="dataUser.role === 'admin' && scope.rowdata.status.code === 'CLOSED' && isSourceDelivery" variant="flat-success" v-b-tooltip.hover title="Reabrir Manifiesto" class="p-0" @click="openModalReopen(scope.rowdata)">
                <feather-icon icon="FolderIcon" />
              </b-button>
            </div>
          </template>
        </table-render>
      </div>
      <b-skeleton-table v-else
          :rows="pagination.limit || 10"
          :columns="schema.length || 10"
          :table-props="{ }"/>
        <modal-manifest-vue :dataManifest="dataManifest" @changeStatusGenerate="changeStatusGenerate" @closeModal="closeManifestModal" :sourceList="source" @updateDataSumamry="updateDataSumamry"></modal-manifest-vue>
        <modal-view-manifest-vue :dataManifest="dataViewManifest" @changeStatusConfirm="changeStatusManifest" @closeModal="closeManifestModal" :source="source" ></modal-view-manifest-vue>
        <modal-close-summary-as-admin :summaryId="summaryId" :deliveries="listDeliveries" @changeStatusManifest="changeStatusManifest"></modal-close-summary-as-admin>
    </b-card>
</template>
<script>
import modalManifestVue from './modalManifest.vue'
import ModalCloseSummaryAsAdmin from './ModalCloseSummaryAsAdmin.vue'
import modalViewManifestVue from './modalViewManifest.vue'
import { environment } from '@/environments/environment'
import BaseServices from '@/store/services/index'
import {endpoint} from './../endpoints.service.js'
export default {
  props: ['rowsData', 'pagination', 'show', 'sourceList'],
  components: {modalManifestVue, ModalCloseSummaryAsAdmin, modalViewManifestVue},
  data() {
    return {
      baseService: new BaseServices(this),
      schema: [],
      actions: [],
      summaryId: null,
      listDeliveries: [],
      dataManifest: {},
      dataViewManifest: {}
    }
  },
  computed: {
    rows() {
      return this.rowsData && this.rowsData.length > 0 ? this.setSchemaDelivery(this.rowsData) : []
    },
    dataUser() {
      return this.$session.get('cas_user')
    },
    source() {
      return this.sourceList || this.$route.query.source || 'delivery'
    },
    isSourceDelivery() {
      return this.source === 'delivery'
    },
    endpointValues() {
      return endpoint[this.source]
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setSchemaDelivery(data) {
      return data.map(el => ({
        ...el,
        id: el.id,
        shipperTable: this.isSourceDelivery ? el.shipper?.name : el.organization?.name || el.shipper?.name,
        creatorUserTable: this.isSourceDelivery ? el.creator_user : el.user_email?.email || 'tracking@enviame.io',
        deliveryCountTable: el.deliveries?.length,
        statusNameTable: `Manifiesto ${el.status?.name}`,
        createdAtTable: this.isSourceDelivery ? el.created_at : this.formatDate(el.created_at),
        updatedAtTable: this.isSourceDelivery ? el.updated_at : this.formatDate(el.updated_at)
      }))
    },
    setInitialData() {
      this.schema = [
        {label: 'Manifiesto', key: 'id'},
        {label: 'Shipper', key: 'shipperTable'},
        {label: 'Courier', key: 'courierSlot', useSlot: true},
        {label: 'Usuario', key: 'creatorUserTable'},
        {label: 'Envíos', key:'deliveryCountTable', class: ['text-center']},
        {label: 'Estado', key: 'statusNameTable'},
        {label: 'Fecha de creación', key: 'createdAtTable'},
        {label: 'Fecha de ultima modificación', key: 'updatedAtTable'},
        {label: 'Acciones', key: 'actionsSlot', useSlot: true, class: ['text-center thead--light']}
      ]
    },
    formatDate(date) {
      return this.$options.filters.dbDateToFormat(date, 'dd-LL-y T')
    },
    closeManifestModal() {
      this.dataManifest = {}
      this.dataViewManifest = {}
    },
    changeStatusGenerate(data) {
      this.changeStatusManifest()
      if (data !== null) {
        this.openModal(data)
      }
    },
    changeStatusManifest() {
      this.$emit('updateManifest', true)
    },
    openModal(data) {
      if (data.status.code === 'OPEN') {
        this.dataManifest = {...data}
        this.$bvModal.show('modalManifest')
      } else {
        this.dataViewManifest = {...data}
        this.$bvModal.show('modalViewManifest')
      }
    },
    openModalReopen(data) {
      const options = {
        title: '',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        okTitle: 'Reabrir',
        cancelTitle: 'Cancelar',
        cancelVariant: 'danger',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-0 border-top-0',
        centered: true
      }
      this.$bvModal.msgBoxConfirm('Esta acción reabrirá el manifiesto', options)
        .then(value => {
          if (value) {
            this.functionReopenManifest(data)
          }
        })
    },
    functionReopenManifest(data) {
      this.baseService.callService(this.endpointValues.postReopenSummaries, null, {idSummary: data.id}, { fullResponseError: true })
        .then(response => {
          this.changeStatusManifest()
        })
        .catch(err => {
          this.alertShow(err)
        })
    },
    openLogs(data) {
      const url = (window.location !== window.parent.location) ? document.referrer  : `${document.location.origin}/`
      window.open(`${url}summaries/${data.id}/logs`, '_blank')
      // window.open(`${environment.platformUrl}/summaries/${data.id}/logs`, '_blank')
    },
    exportDeliveries(data) {
      this.baseService.callService(this.endpointValues.getExportDeliveries, null, {idSummary: data.id}, { fullResponseError: true })
        .then(response => {
          window.open(response.url, '_blank')
        })
        .catch(err => {
          this.alertShow(err)
        })
    },
    alertShow(err) {
      const text = 'Ha sucedido un error vuelve a intentarlo'
      let textMessage = ''
      if (typeof err === 'object' && typeof err[0] === 'string' && err[0] !== '') {
        textMessage = err[0]
      } else if (Array.isArray(err)) {
        textMessage += '<ul style="list-style-type: disc;">'
        err.forEach(element => {
          textMessage += `<li>${element.message}</li>`
        })
        textMessage += '</ul>'
      }
      this.$alert(textMessage ? textMessage : text)
    },
    updateDataSumamry (data) {
      this.summaryId = data.id 
      this.listDeliveries = data.deliveries_summary
    }
  }
}
</script>
<style lang="scss">
  .table-manifest--buttons {
    display: grid;
    gap: 10px;
    grid-auto-flow: column;
    justify-content: center;
  }
</style>