<template>
  <b-modal id="modalViewManifest" centered size="xl" hide-footer title="Previsualizador" @hide="resetValue">
    <div class="w-100" v-if="!!dataManifest">
        <b-tabs content-class="mt-3">
          <b-tab title="Manifiesto" active>
            <iframe class="preview-iframe-manifest" :src="dataManifest.summary_url" title="previsualización" view="FitH">
            </iframe>
            <p>Si no puedes previsualizar el PDF haz <a :href="dataManifest.summary_url" target="_blank" class="text-primary">click aquí</a></p>
          </b-tab>
          <b-tab title="Etiquetas">
            <iframe class="preview-iframe-manifest" :src="dataManifest.labels_url" title="previsualización" view="FitH">
            </iframe>
            <p>Si no puedes previsualizar el PDF haz <a :href="dataManifest.labels_url" target="_blank" class="text-primary">click aquí</a></p>
          </b-tab>
          <b-tab title="Confirmación">
            <div class="preview-iframe-manifest preview-iframe-confirmation">
              <!-- {{!!dataManifest.files ? 'hay' : 'no hay'}} -->
              <voucher-upload-vue
                :voucher="dataFileManifest"
                :summary_id="dataManifest.id"
                :source="source"
                @confirm-summary="reviewData">
              </voucher-upload-vue>
            </div>
          </b-tab>
        </b-tabs>
    </div>
  </b-modal>
</template>
<script>
import voucherUploadVue from '../../summaries/voucherUpload.vue'
export default {
  props: ['dataManifest', 'source'],
  components: {voucherUploadVue},
  data() {
    return {
      dataUpload: null
    }
  },
  computed: {
    dataFileManifest() {
      return !!this.dataManifest.files ? this.dataManifest.files : this.dataUpload === null ? null : this.dataUpload
    }
  },
  methods: {
    reviewData(data) {
      this.$emit('changeStatusConfirm', true)
      this.dataUpload = [...data?.data?.voucher]
    },
    resetValue() {
      this.dataUpload = null
      this.$emit('closeModal', true)
    }
  }
}
</script>
<style lang="scss">
  .preview-iframe-manifest {
    width: 100%;
    object-fit: contain;
    display: grid;
    justify-content: center;
    align-items: center;
    height: 70vh;
  }
  .preview-iframe-confirmation {
    align-items: baseline;
    height: 71vh;
  }
</style>