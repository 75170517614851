<template lang="">
  <b-modal id="modalManifest" centered size="lg" @show="showModal" @hide="hideModal" title="Vista Previa Manifiesto" hide-footer body-class="p-0" title-class="h1">
    <b-overlay :show="!(rows.length > 0) || loading.closeManifest || loading.addManifest" rounded="sm" opacity="1" variant="white">
      <b-card class="mb-0 pb-0 pl-3 pr-3 pt-2" no-body>
        <h3>Haz click en "Generar cierre de manifiesto" para imprimirlo(*)</h3>
          <form-render :form.sync="form" :fields="fieldsForm" @send="addManifest">
            <template #buttonGroupSlot >
              <b-button type="submit" variant="warning" :disabled="loading.addManifest" class="w-100">
                Añadir envío
              </b-button>
            </template>
          </form-render>
          <table-render :schema="schema" :rows="rows" :fixed="true" showCheckboxes="true" :selectedRows.sync="selectedRows">
          </table-render>
          <div class="modal-manifest--button--container">
            <b-button variant="warning" :disabled="!selectedRows.length > 0" @click="keepManifest">Mantener manifiesto activo</b-button>
            <b-button variant="success" :disabled="!selectedRows.length > 0" @click="closeManifest">Generar cierre de manifiesto</b-button>
          </div>
      </b-card>
    </b-overlay>
  </b-modal>
</template>
<script>
import BaseServices from '@/store/services/index'
import {endpoint} from './../endpoints.service.js'
export default {
  props: ['dataManifest', 'sourceList'],
  data() {
    return {
      baseService: new BaseServices(this),
      schema: [],
      actions: [],
      rows: [],
      selectedRows: [],
      dataUseManifest: null,
      loading: {
        closeManifest: false,
        addManifest: false
      },
      form: {},
      fieldsForm: []
    }
  },
  computed: {
    source() {
      return this.sourceList || this.$route.query.source || 'delivery'
    },
    endpointValues() {
      return endpoint[this.source]
    },
    dataUser() {
      return this.$session.get('cas_user')
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    addManifest() {
      this.loading.addManifest = true
      const queryParams = {
        number: this.form.deliveryId
      }
      this.baseService.callService(this.endpointValues.getAddDeliverySummaries, queryParams, {idSummary: this.dataUseManifest.id})
        .then(response => {
          if (this.checkAddManifest(response)) {
            this.addNewDelivery(response.delivery)
          }
        })
        .catch(err => {
          console.error(err)
          this.$alert('El envío no existe, no pertenece al grupo de servicio o se encuentra en movimiento.')
        })
    },
    checkAddManifest(response) {
      return this.rows.findIndex(el => el.id === response.delivery.id) === -1
    },
    closeManifest() {
      // Primero se verifica si existen envios en movimiento
      const deliveriesOnMove = this.rows.filter(delivery => ![5, 26, 75, 68].includes(delivery.status.id))
      if (deliveriesOnMove.length > 0 && this.dataUser.role === 'admin') {
        this.$emit('updateDataSumamry', { ...this.dataManifest, deliveries_summary: this.rows })
        this.$bvModal.hide('modalManifest')
        this.$bvModal.show('modalCloseSummaryAsAdmin')
        return
      }
      this.loading.closeManifest = true
      const queryParams = {
        id: this.dataUseManifest.id,
        replaceDeliveries: [...this.selectedRows]
      }
      
      this.baseService.callService(this.endpointValues.postCloseSummaries, queryParams, {idSummary: this.dataUseManifest.id}, { fullResponseError: true })
        .then(response => {
          this.$bvModal.hide('modalManifest')
          this.$success('Se ha generado manifiesto de forma exitosa')
          this.$emit('changeStatusGenerate', response)
        })
        .catch(err => {
          this.alertShow(err)
        })
        .finally(() => {
          this.loading.closeManifest = false
        })
        
    },
    alertShow(err) {
      const text = 'Ha sucedido un error vuelve a intentarlo'
      let textMessage = ''
      if (typeof err === 'object' && typeof err[0] === 'string' && err[0] !== '') {
        textMessage = err[0]
      } else if (Array.isArray(err)) {
        textMessage += '<ul style="list-style-type: disc;">'
        err.forEach(element => {
          textMessage += `<li>${element.message}</li>`
        })
        textMessage += '</ul>'
      }
      this.$alert(textMessage ? textMessage : text)
    },
    keepManifest() {
      this.loading.closeManifest = true
      const queryParams = {
        id: this.dataUseManifest.id,
        confirmed_deliveries: [...this.selectedRows]
      }
      this.baseService.callService(this.endpointValues.postKeepSummaries, queryParams, {idSummary: this.dataUseManifest.id}, { fullResponseError: true })
        .then(response => {
          this.$bvModal.hide('modalManifest')
          this.$success('Se ha modificado el manifiesto de forma exitosa')
          this.$emit('changeStatusGenerate', true)
        })
        .catch(err => {
          this.alertShow(err)
        })
        .finally(() => {
          this.loading.closeManifest = false
        })
    },
    setInitialData() {
      this.schema = [
        {label: 'Comercio', key: 'shipper.name'},
        {label: 'Nº de envío', key: 'order.imported_id'},
        {label: 'Orden de flete', key: 'carrier.tracking_number'},
        {label: 'Cliente', key: 'origin.contact.name'},
        {label: 'Comuna', key:'destination.address.level3'}
      ]
      this.fieldsForm = [
        { fieldType: 'FieldInput', name: 'deliveryId', label: '', clearable: true, containerClass: 'col-sm-9 container-info p-0', noZIndex: true},
        {name: 'buttonGroupSlot', useSlot:true, containerClass: 'col-sm-3 p-0'}
      ]
    },
    addNewDelivery(delivery) {
      const references = [delivery.id]
      const queryParams = {
        delivery_type: this.source,
        reference_type: 'internal',
        references
      }
      this.baseService.callService(this.endpointValues.getDeliveriesManifest, queryParams, {})
        .then(response => {
          this.rows = [...this.rows, ...response.data]
          this.selectedRows = [...this.selectedRows, ...references]
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.loading.addManifest = false
        })
    },
    getData() {
      this.setAllDefault()
      const references = this.dataUseManifest?.deliveries ? [...this.dataUseManifest.deliveries] : []
      const queryParams = {
        delivery_type: this.source,
        reference_type: 'internal',
        created_at_from: '2020-01-01T00:00:00Z',
        references
      }
      this.baseService.callService(this.endpointValues.getDeliveriesManifest, queryParams, {})
        .then(response => {
          this.rows = [...response.data]
          this.selectedRows = [...references]
        })
        .catch(err => {
          console.error(err)
        })
    },
    hideModal() {
      this.dataUseManifest = null
      this.$emit('closeModal', true)
      this.setAllDefault()
    },
    setAllDefault() {
      this.form = {}
      this.rows = []
      this.selectedRows = []
    },
    showModal() {
      this.dataUseManifest = null
      this.recallGetData()
    },
    recallGetData() {
      if (!!(this.dataManifest && this.dataManifest.deliveries) && this.dataUseManifest === null) {
        this.dataUseManifest = {...this.dataManifest}
        this.getData()
      } else {
        setTimeout(() => {
          this.recallGetData()
        }, 1000)
      }
    }
  }
}
</script>
<style lang="scss">
  .modal-manifest--button--container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    padding: 2rem;
  }
</style>