<template>
  <div>
  <!-- Sección formulario -->
  <section v-if="show.form">
    <div class="container-info">
      <b-alert class="p-1" variant="primary" show>
        <div class="d-flex justify-content-center align-items-center">
          <feather-icon size="30" class="mr-50" icon="InfoIcon"/>
          <p>
            Antes de confirmar, le recordamos que esta acción se debe realizar posterior al retiro y el manifiesto debe estar firmado.
          </p>
        </div>
      </b-alert>

      <label>Cargar archivos (formato PDF, PNG o JPG)</label>
      <b-form-file
        v-model="files"
        :browse-text="$t('Buscar')" 
        :placeholder="$t('Escoja un archivo')"
        :drop-placeholder="$t('Suelte su archivo aquí')"
        multiple
        accept=".pdf,.png,.jpg,.jpeg"
      />
      <b-alert v-model="errorUpdate" v-height-fade.appear variant="danger" class="w-100 mt-1">
        <div class="alert-body">
          {{ $t('Error al subir archivo:') }}
          {{ dataErrorsFile }}
        </div>
      </b-alert>
    </div>
    <b-button
      variant="success"
      class="push-right mt-2"
      @click="uploadVoucher"
      :disabled="loading.confirmSummary">
        <span class="mr-1">{{ $t('label-btn-modal-voucher') }}</span>
        <feather-icon v-if="!loading.confirmSummary" icon="UploadIcon" /> 
        <i v-else :class="['fa', 'fa-spinner', 'fa-spin']"/>
    </b-button>
  </section>

  <!-- Sección mostrar comprobantes -->
  <section v-else class="my-2">
    <h5>Listado de comprobantes</h5>
    <!-- Listado si existen comprobantes -->
    <b-list-group>
      <b-list-group-item 
        v-for="(file, index) in voucher" :key="index" 
        @click="goToVoucherFile(file.url)"
        button>
          <span> {{ file.name }} </span>
      </b-list-group-item>
    </b-list-group>
    <!-- Mensaje de voucher vacío -->
    <span v-if="show.emptyVoucherMessage">
      No se subió comprobante de la entrega. 
    </span>
  </section>
  </div>
</template>
<script>
import SummariesService from './summaries.service'
export default {
  name: 'voucher-upload',
  props: ['voucher', 'summary_id', 'source'],
  data () {
    return {
      files: null,
      dataErrorsFile: '',
      errorUpdate: false,
      loading: {
        confirmSummary: false
      },
      summariesService: new SummariesService(this)
    }
  },
  computed: {
    show() {
      let form = false
      let emptyVoucherMessage = false
      if (this.voucher?.length === 0) {
        form = false
        emptyVoucherMessage = true
      } else if (this.voucher?.length > 0) {
        form = false
        emptyVoucherMessage = false
      } else {
        form = true
      }
      return {
        form,
        emptyVoucherMessage
      }
    },
    referenceType() {
      return this.source ? this.source : 'delivery'
    }
  },
  methods: {
    goToVoucherFile (url) {
      window.open(url, '_blank')
    },
    uploadVoucher () {
      this.errorUpdate = false

      const formData = new FormData()
      for (const file of this.files) {
        formData.append('files', file)
      }
      if (this.referenceType) formData.append('reference_type', this.referenceType)
      const params = {
        summary_id: this.$route.params.id_summary || this.summary_id
      }
      this.loading.confirmSummary = true
      this.summariesService.uploadFilesToConfirmSummary('confirmSummary', formData, params)
        .then(resp => {
          this.$emit('confirm-summary', resp)
        })
        .catch(error => {
          this.errorUpdate = true
          this.dataErrorsFile = error
        })
        .finally(() => {
          this.loading.confirmSummary = false
        })
    }
  }
}
</script>