import { environment } from '@/environments/environment'
import useJwt from '@/auth/jwt/useJwt'

const services = [
  { name: 'getSummary', url: `${environment.estambulApiUrl}/summaries/{summary_id}/details`, method: 'get'},
  { name: 'confirmSummary', url: `${environment.estambulApiUrl}/confirm-summary/{summary_id}`, method: 'post'}
]

export default class SummariesService {
  constructor (context) {
    this.context = context
  }
  async callService (name, queryParams = {}, params = {}) {
    const service = services.filter(service => service.name === name)[0]
    return useJwt.genericService(service, queryParams, params)
  }
  
  async callMultipleServices (callArray, byName = false) {
    const servicesArray = callArray.map(call => ({ service: services.filter(service => service.name === call.name)[0], ...call }))
    return useJwt.genericMultipleService(servicesArray, byName)
  }
  /**
   * Función para confirmar el manifiesto y subida de archivos
   * @param {String} name Corresponde al nombre del servicio a consultar
   * @param {FormData} formData Archivos de confirmación
   * @param {*} params parametros de la url (summary_id)
   */
  async uploadFilesToConfirmSummary (name, formData, params = {}) {
    const service = services.filter(service => service.name === name)[0]
    return useJwt.genericUploadFile(service, formData, params, true)
  }
}