<template>
  <div>
    <b-modal id="modalCloseSummaryAsAdmin"
      :title="$t('Cierre de manifiesto')"
      no-close-on-esc
      no-close-on-backdrop
      centered
      size="md"
      @show="onShowModal"
      hide-footer>
        <b-alert show variant="warning" class="p-2 text-center">
          Este manifiesto contiene envíos con movimiento.
          Seleccione la opción adecuada según lo manifestado por el seller.
        </b-alert>

        <div class="mt-1">
          <label class="font-weight-bolder h6">Indique alguna de las siguientes opciones <span class="text-danger">*</span> </label>
          <b-form-group v-slot="{ ariaDescribedby }" plain stacked>
            <b-form-radio class="mb-1" v-for="(element, index) in optionsClose" :key="index" :disabled="element.disabled" v-model="opionToClose" :aria-describedby="ariaDescribedby" :value="element.value">{{element.text}}</b-form-radio>
          </b-form-group>
        </div>

        <!-- Botones volver y confirmar -->
        <div class="col-md-12 mt-1">
          <!-- <b-button @click="backToSummary" variant="warning">
            <feather-icon icon="ArrowLeftIcon"/> Volver
          </b-button> -->
          <b-button @click="closeSummaryAsAdmin" :disabled="modalData.btnDisabled" class="push-right" variant="success">
            Cerrar manifiesto
            <feather-icon v-if="!modalData.btnLoading" icon="CheckIcon"/>
            <i v-else :class="['fa', 'fa-spinner', 'fa-spin']"/> 
          </b-button>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'
import {endpoint} from './../endpoints.service.js'
export default {
  props: ['summaryId', 'deliveries'],
  data () {
    return {
      modalData: {
        btnDisabled: true,
        btnLoading: false
      },
      opionToClose: null,
      optionsClose: [],
      baseService: new BaseServices(this)
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    }),
    source() {
      return this.sourceList || this.$route.query.source || 'delivery'
    },
    endpointValues() {
      return endpoint[this.source]
    }
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    opionToClose (value) {
      if (value) this.modalData.btnDisabled = false
      else this.modalData.btnDisabled = true
    },
    deliveries: {
      handler(value) {
        // [5, 26, 75, 68] Todo estado de envios distinto a este array se considera "en movimiento"
        const optionsToDisable = ['CLOSE_BOTH', 'CLOSE_ONE']
        const allDeliveriesInMove = value.every(item => ![5, 26, 75, 68].includes(item.status.id))
        this.optionsClose = this.optionsClose.map(option => ({
          ...option,
          disabled: allDeliveriesInMove ? optionsToDisable.includes(option.value) : false
        }))
      },
      deep: true
    }
  },
  mounted () {
    this.getAllInitialData()
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.optionsClose = [
        {
          text: 'Cerrar manifiesto combinado (Envíos con movimiento + envíos sin movimiento).',
          value: 'CLOSE_ANYWAY',
          disabled: false
        },
        {
          text: 'Cierra en dos manifiestos, uno con envíos en movimiento y otro sin movimiento.',
          value: 'CLOSE_BOTH',
          disabled: false
        },
        {
          text: 'Cerrar manifiesto de envíos con movimiento y mantener los envíos sin movimiento activos en un nuevo manifiesto.',
          value: 'CLOSE_ONE',
          disabled: false
        }
      ]
    },
    onShowModal () {
      this.opionToClose = null
    },
    backToSummary () {
      this.$bvModal.show('modalManifest')
      this.$bvModal.hide('modalCloseSummaryAsAdmin')
    },
    closeSummaryAsAdmin () {
      const queryParams = {
        code: this.opionToClose
      }
      this.modalData.btnLoading = true
      this.modalData.btnDisabled = true
      this.baseService.callService(this.endpointValues.closeSummariesAsAdmin, queryParams, { summary_id: this.summaryId })
        .then(response => {
          this.$bvModal.hide('modalCloseSummaryAsAdmin')
          this.$emit('changeStatusManifest')
          this.showSuccessMessage(queryParams.code, response)
        })
        .catch(err => {
          console.error(err)
          this.$alert(err)
        })
        .finally(() => {
          this.modalData.btnLoading = false
          this.modalData.btnDisabled = false
        })
    },
    showSuccessMessage(closeCode, response) {
      let textAlert = ''
      if (closeCode === 'CLOSE_ANYWAY') {
        textAlert = 'Se ha cerrado correctamente el manifiesto. Ya se encuentra disponible la descarga y confirmación.'
      } else if (closeCode === 'CLOSE_BOTH') {
        textAlert = `Se han cerrado correctamente los manifiestos. Ya se encuentra disponible la descarga y confirmación. ID Manifiesto actual cerrado: ${response.data.closed_summary.id} y ID nuevo manifiesto cerrado: ${response.data.new_summary.id}`
      } else if (closeCode === 'CLOSE_ONE') {
        textAlert = `Los envíos con movimiento se han cerrado correctamente. Y se ha creado un nuevo manifiesto activo con los envíos sin movimiento. ID Manifiesto actual cerrado: ${response.data.closed_summary.id} y ID nuevo manifiesto activo: ${response.data.new_summary.id}`
      }
      this.$success(textAlert)
    }
  }
}
</script>

<style>

</style>