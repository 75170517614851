<template>
  <b-card no-body class="m-0">
    <form-render :form.sync="form" :fields="fieldsForm" @send="filterData">
      <template #buttonGroupSlot >
        <b-button type="submit" variant="warning">
          <feather-icon icon="SearchIcon"/>
        </b-button>
        <!-- <b-button variant="outline-light" v-b-tooltip.hover :title="$t('Limpiar filtros')" class="ml-2" @click="clearFilter"><feather-icon icon="RefreshCwIcon"/></b-button> 
        <b-button v-b-modal.modalAdvanced variant="link" class="pt-0 pb-0">
          Filtro avanzado
        </b-button> -->
        <b-dropdown class="mx-1" v-b-tooltip.hover :title="$t('Más opciones')" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="light"> 
          <template #button-content><feather-icon icon="SettingsIcon"/></template>
          <b-dropdown-item @click="clearFilter">{{$t('Limpiar filtros')}}</b-dropdown-item>
          <b-dropdown-item v-b-modal.modalAdvanced>{{$t('Búsqueda avanzada')}}</b-dropdown-item>
        </b-dropdown>
      </template>
    </form-render>
    <b-modal id="modalAdvanced" centered title="Filtro Avanzado" ok-title="Buscar" cancel-title="Limpiar" @cancel="clearFilter" @ok="filterData">
      <form-render :form.sync="form" :fields="fieldsFormAdvanced"></form-render>
    </b-modal>
  </b-card>
</template>
<script>
import BaseServices from '@/store/services/index'
import {endpoint} from './../endpoints.service.js'

export default {
  props: ['sourceList'],
  data() {
    return {
      baseService: new BaseServices(this),
      user: {},
      controlHeight: { class: 'row mb-12 spacing-label-field'},
      form: {},
      fieldsForm: [],
      fieldsFormAdvanced: [],
      ROLES : {
        marketplace: 'marketplace',
        ecommerce: 'ecommerce',
        admin: 'admin',
        superadmin: 'superadmin'
      },
      dataStatus: [
        {
          'id': 'OPEN',
          'text': 'Manifiestos abiertos'
        },
        {
          'id': 'CLOSED',
          'text': 'Manifiestos cerrados'
        },
        {
          'id': 'CONFIRMED',
          'text': 'Manifiestos confirmados'
        }
      ],
      dataOrigin: [
        {
          id: 'mumbai',
          text: 'Mumbai'
        }
      ]
    }
  },
  computed: {
    source() {
      return this.sourceList || this.$route.query.source || 'delivery'
    },
    endpointValues() {
      return endpoint[this.source]
    }
  },
  mounted() {
    this.setData()
  },
  methods: {
    formatDate(date) {
      return date ? this.$options.filters.dbDateUTCToGMTlocal(date, 'YYYY-MM-DD') : ''
    },
    filterData() {
      this.$emit('filterForm', this.refactorFilter())
    },
    refactorFilter() {
      const response = {}
      for (const key in this.form) {
        if (this.form.hasOwnProperty.call(this.form, key)) {
          const element = this.form[key]
          if (element) {
            switch (key) {
            case 'shipper_id':
            case 'pudo_id':
            case 'summary_origin':
              response[key] = element.id
              break
            case 'carrier_code':
              response[key] = element.code
              break
            case 'status_code':
              if (element?.length > 0) {
                element.forEach((statusCode, index) => {
                  response[`status_code[${index}]`] = statusCode.id
                })
              }
              break
            case 'last_message_from':
              response.created_at_from = this.formatDate(element.start)
              response.created_at_to = this.formatDate(element.end)
              break
          
            default:
              response[key] = element
              break
            }
          }
        }
      }
      return response
    },
    clearFilter() {
      this.setInitialFilters()
      this.filterData()
    },
    setInitialFilters () {
      this.form = {
        status_code : [
          {
            'id': 'OPEN',
            'text': 'Manifiestos abiertos'
          }
        ]
      }
      // if (this.user.role !== this.ROLES.admin) {
      //   this.form.shipper_id = this.user.role === this.ROLES.ecommerce ? this.user.shipper.id : this.user.role === this.ROLES.marketplace ? this.user.organization.id : 0
      // }
    },
    getShippers(value) {
      const params = {}
      return this.baseService.callService(this.endpointValues.getShippers, {name: value, paginate_by: 40}, params)
        .then(response => {
          return response.data.map(el => ({...el, text: `${el.id} - ${el.name1 ? el.name1 : el.name}`}))
        })
        .catch(error => console.error(error))
    },
    setData() {
      const commonFiles = [
        { fieldType: 'FieldInput', name: 'id', label: 'Nº de manifiesto', clearable: true, containerClass: 'col-sm-12 container-info col-md-2', noZIndex: true},
        { fieldType: 'FieldSelect', name: 'status_code', multiple: true, clearable: true, label: 'Estado de manifiesto', containerClass: 'col-sm-12 container-info col-md-3', options: this.dataStatus, searchOnType: { nChars: 999}},
        { fieldType: 'FieldDatepicker', name: 'last_message_from', range: true, clearable: true, label: 'Fecha último mensaje', containerClass: 'col-sm-12 container-info col-md-3'}
      ]
      this.fieldsForm = [
        ...commonFiles,
        {name: 'buttonGroupSlot', useSlot:true, containerClass: 'col-auto mt-2'}
      ]
      this.fieldsFormAdvanced = [
        {...commonFiles[0], containerClass: 'col-sm-12 container-info'},
        {...commonFiles[1], containerClass: 'col-sm-12 container-info'},
        {...commonFiles[2], containerClass: 'col-sm-12 container-info'},
        { fieldType: 'FieldSelect', name: 'shipper_id', clearable: true, label: 'Empresa', containerClass: 'col-sm-12 container-info', searchOnType: { fx: this.getShippers, nChars: 3, debounce: 600 }},
        { fieldType: 'FieldSelect', name: 'carrier_code', clearable: true, label: 'Courier', containerClass: 'col-sm-12 container-info', options: [], searchOnType: { nChars: 999}},
        { fieldType: 'FieldSelect', name: 'pudo_id', clearable: true, label: 'Pudo/Punto de Retiro', containerClass: 'col-sm-12 container-info', persistSearch: true, searchOnType: { fx: e => this.searchPudo(e), nChars: 3, debounce: 600 }},
        { fieldType: 'FieldInput', name: 'creator_email', label: 'Usuario', containerClass: 'col-sm-12 container-info', placeholder: 'Ingrese el email de un usuario'},
        { fieldType: 'FieldSelect', name: 'summary_origin', clearable: true, label: 'Origen', containerClass: 'col-sm-12 container-info', options: this.dataOrigin, searchOnType: { nChars: 999}},
        {name: 'buttonGroupSlot', useSlot:true, containerClass: 'col-12'}
      ]
      this.user = this.$session.get('cas_user')
      this.customFormByRole()
      this.setInitialFilters()
      this.getCarriersData()
    },
    customFormByRole () {
      if (this.user.role !== this.ROLES.admin)  this.fieldsFormAdvanced = this.fieldsFormAdvanced.filter(field => !['shipper_id'].includes(field.name))
    },
    searchPudo(value) {
      const queryParams = {
        search_by: value
      }
      return this.baseService.callService(this.endpointValues.getPudos, queryParams)
        .then(resp => {
          const response = resp.pudos
          response.map((row) => {
            Object.keys(row).forEach((key) => {
              return row[key] = `${row[key]}`
            })
            row.text = `${row.name} (${row.code})`
          })
          return response
        })
        .catch(err => {
          return err
        }) 
    },
    getCarriersData() {
      const queryParams = {}
      this.baseService.callService(this.endpointValues.getCarriers, queryParams)
        .then(response => {
          this.fieldsFormAdvanced.map((el) => this.updateOptions(el, response.data))
          this.fieldsForm.map((el) => this.updateOptions(el, response.data))
        })
        .catch(err => {
          console.error(err)
        })
    },
    updateOptions(el, data) {
      if (el.name === 'carrier_code') {
        el.options = [...this.optionsSelect(data)]
      }
    },
    optionsSelect(data) {
      return data.map((element) => ({
        ...element,
        id: element.code,
        text: element.name
      }))
    }
  }
}
</script>