export const endpoint = {
  delivery: {
    getManifestList: 'getManifestList',
    getCarriers: 'getCarriersNY',
    getShippers: 'getShippers',
    getPudos: 'getPudosPlat',
    getAddDeliverySummaries: 'getAddDeliverySummaries',
    postCloseSummaries: 'postCloseSummaries',
    closeSummariesAsAdmin: 'closeSummariesAsAdmin',
    postKeepSummaries: 'postKeepSummaries',
    getDeliveriesManifest: 'getDeliveriesManifest',
    postReopenSummaries: 'postReopenSummaries',
    getExportDeliveries: 'getExportDeliveries'
  },
  return: {
    getManifestList: 'getManifestListReturn',
    getCarriers: 'getCarriersNY',
    getShippers: 'getShippers',
    getPudos: 'getPudosPlat',
    getAddDeliverySummaries: 'getAddDeliverySummaries',
    postCloseSummaries: 'postCloseSummariesReturn',
    postKeepSummaries: 'postKeepSummaries',
    getDeliveriesManifest: 'getDeliveriesManifest',
    postReopenSummaries: 'postReopenSummaries',
    getExportDeliveries: 'getExportDeliveries'
  }
}