<template>
  <div>
    <b-card>
      <filter-component @filterForm="filterForm" :sourceList="source"></filter-component>
      <table-component :rowsData="tableData" :pagination="pagination" :show="show" @updateManifest="updateManifest" :sourceList="source"></table-component>
      <pagination :pagination="pagination" :noDigits="pagination.noShowDigits" :showSize="true"/>
    </b-card>
  </div>
</template>
<script>
import tableComponent from './components/table.vue'
import filterComponent from './components/filter.vue'
import BaseServices from '@/store/services/index'
import {endpoint} from './endpoints.service.js'
export default {
  components: {tableComponent, filterComponent},
  props: ['sourceList'],
  data() {
    return {
      baseService: new BaseServices(this),
      tableData: [],
      dataFilter: {},
      show: {
        overlay: false,
        filter: false
      },
      ROLES : {
        marketplace: 'marketplace',
        ecommerce: 'ecommerce',
        seller: 'seller',
        admin: 'admin',
        superadmin: 'superadmin'
      },
      pagination: {
        page: 1,
        total: 0,
        limit: 20,
        noShowDigits: true,
        calculateCountPage: false
      },
      form: {}
    }
  },
  computed: {
    dataUser() {
      return this.$session.get('cas_user')
    },
    source() {
      return this.sourceList || this.$route.query.source || 'delivery'
    },
    endpointValues() {
      return endpoint[this.source]
    }
  },
  mounted() {
    this.checkConditionUser()
  },
  watch: {
    'pagination.page'() {
      this.getData()
    },
    'pagination.limit'() {
      this.getData()
    }
  },
  methods: {
    checkConditionUser() {
      !!this.dataUser ? this.firstLoad() : setTimeout(() => {
        this.$router.go(this.$router.currentRoute)
      }, 1000)
    },
    firstLoad() {
      this.form = {}
      this.getData()
    },
    filterForm(data) {
      this.form = {...data} 
      if (this.pagination.page === 1) {
        this.getData()
      } else {
        this.pagination.page = 1
      }
    },
    getData() {
      this.show.overlay = true
      const params = {}
      const queryParams = this.setQueryParams()
      this.baseService.callService(this.endpointValues.getManifestList, queryParams, params)
        .then(response => {
          this.tableData = response.data
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.show.overlay = false
        }) 
    },
    setQueryParams() {
      const queryParams = {
        ...this.form,
        'limit': this.pagination?.limit ?? 20,
        'page': this.pagination?.page ?? 1,
        'timezone': this.dataUser.timezone ?? 'America/Santiago'

      }

      if (this.dataUser.role !== this.ROLES.admin) {
        if (this.dataUser.role === this.ROLES.ecommerce || this.dataUser.role === this.ROLES.seller) queryParams.shipper_id = this.dataUser.shipper.id
        if (this.dataUser.role === this.ROLES.marketplace) queryParams.organization_id = this.dataUser.organization.id
      }
      return queryParams
    },
    formatDate(date) {
      return this.$options.filters.dbDateToFormat(date, 'dd-LL-y T')
    },
    updateManifest(data) {
      setTimeout(() => {
        this.getData()
      }, 2000)
    }
  }
}
</script>